import { Link } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import { Menu } from '@mui/icons-material';

import { HeaderButton } from './HeaderButton';
import { HeaderMenu } from './HeaderMenu';

import { NavBar, LeftNavBarContent, RightNavBarContent } from './Header.styled';

interface HeaderNode {
  key: string;
  label: React.ReactNode;
  display?: boolean;
}

export interface HeaderNodeLink extends HeaderNode {
  to: string;
  onClick?: never;
  menu?: never;
}

export interface HeaderNodeAction extends HeaderNode {
  to?: never;
  onClick?: () => void;
  menu?: never;
}

export interface HeaderNodeMenu extends HeaderNode {
  to?: never;
  onClick?: never;
  menu: Array<HeaderNodeLink | HeaderNodeAction>;
}

export interface HeaderContent {
  logo: React.ReactNode;
  left: Array<HeaderNodeLink | HeaderNodeAction>;
  right: Array<HeaderNodeLink | HeaderNodeAction | HeaderNodeMenu>;
}

interface HeaderProps {
  content: HeaderContent;
}

export function Header({ content }: HeaderProps) {
  const theme = useTheme();
  const leftMenuInIcon = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <NavBar>
      <LeftNavBarContent>
        {leftMenuInIcon ? (
          buildHeaderNode({
            key: 'logo',
            label: (
              <>
                <Menu />
                {content.logo}
              </>
            ),
            menu: content.left,
          })
        ) : (
          <>
            {content.logo}
            {content.left
              .filter((child) => child.display ?? true)
              .map((child) => buildHeaderNode(child))}
          </>
        )}
      </LeftNavBarContent>
      <RightNavBarContent>
        {content.right
          .filter((child) => child.display ?? true)
          .map((child) => buildHeaderNode(child))}
      </RightNavBarContent>
    </NavBar>
  );
}

export function buildHeaderNode(
  child: HeaderNodeLink | HeaderNodeAction | HeaderNodeMenu
) {
  if (child.menu) {
    return (
      <HeaderMenu key={child.key} menuKey={child.key} menu={child.menu}>
        {child.label}
      </HeaderMenu>
    );
  } else {
    if (child.to) {
      if (child.to.startsWith('http')) {
        return (
          <HeaderButton key={child.key} component="a" href={child.to}>
            {child.label}
          </HeaderButton>
        );
      } else {
        return (
          <HeaderButton key={child.key} component={Link} to={child.to}>
            {child.label}
          </HeaderButton>
        );
      }
    } else {
      return (
        <HeaderButton key={child.key} onClick={child.onClick}>
          {child.label}
        </HeaderButton>
      );
    }
  }
}
