import { createContext, useContext } from 'react';

import { UserData, emptyUser } from 'data/user';

export interface IUserContext {
  user: UserData;
  setUser: (newUser: UserData) => void;
}

const emptyUserContext = {
  user: emptyUser,
  setUser: (newUser: UserData) => {},
};
export const userContext = createContext<IUserContext>(emptyUserContext);
export const useUserContext = () => useContext(userContext);

export function buildUserContext(
  user: UserData,
  setUser: React.Dispatch<React.SetStateAction<UserData>>
): IUserContext {
  return {
    user,
    setUser: (newUser: UserData) => {
      setUser(newUser);
    },
  };
}
