import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { AccountCircle } from '@mui/icons-material';

import { LoginForm, RegisterButton } from 'components/domain';
import { FormContainer, CancelButton } from 'components/ui';
import { Logo } from 'images';

import { LogoContainer, UserName } from './Login.styled';

export function Login() {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState<boolean>(false);
  const onClose = () => setOpen(false);

  return (
    <>
      <AccountCircle />
      <UserName onClick={() => setOpen(true)}>{t('header.login')}</UserName>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={false}
        fullScreen={fullScreen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('header.login')}</DialogTitle>
        <DialogContent id="alert-dialog-description">
          <LogoContainer>
            <Logo />
            <Typography variant="h4" component="h1" color="success.main">
              {t('login.welcome')}
            </Typography>
          </LogoContainer>
          <FormContainer>
            <LoginForm onSubmit={onClose} />
            <CancelButton
              variant="outlined"
              color="success"
              fullWidth
              onClick={onClose}
            >
              {t('form.cancel')}
            </CancelButton>
          </FormContainer>
          <RegisterButton />
        </DialogContent>
      </Dialog>
    </>
  );
}
