import { get, post } from 'tools/fetch';
import { BackEndUserData, UserData, formatBackEndUser } from './user';

export async function logUserIn(
  email: string,
  password: string,
  rememberMe: boolean
): Promise<UserData> {
  const tokenUrl = `${process.env.REACT_APP_BACK_URL}/loginToken`;
  const token = await get<string>(tokenUrl);

  // TODO : locale should be set on all requests ? Or set the session at some point ?
  const rememberMeParam = rememberMe ? '?_remember_me=on' : '';
  const url = `${process.env.REACT_APP_BACK_URL}/login_json${rememberMeParam}`;
  const body = { email, password, _csrf_token: token };

  const backEndUser = await post<typeof body, BackEndUserData>(url, body);
  return formatBackEndUser(backEndUser);
}

export function logUserOut() {
  const logoutUrl = `${process.env.REACT_APP_BACK_URL}/logout`;
  return get<void>(logoutUrl);
}
