import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  TextField,
} from '@mui/material';

import { useUserContext, useCurrentPositionContext, IPosition } from 'contexts';
import { ErrorMsg, FormMsg, Form, SubmitButton } from 'components/ui';
import { SpeciesData, fetchSpeciesList } from 'data/species';
import { LightVarietyData } from 'data/variety';
import { addSpot, SpotData } from 'data/spot';

export interface IAddSpotFormProps {
  onSubmit: (spot: SpotData) => void;
  coordinates?: IPosition;
  readonlyCoordinates?: boolean;
}

export function AddSpotForm({
  onSubmit,
  coordinates,
  readonlyCoordinates = false,
}: IAddSpotFormProps) {
  const { user } = useUserContext();
  const currentPosition = useCurrentPositionContext();
  const { t } = useTranslation();
  const [existingSpecies] = useState<SpeciesData[]>(() =>
    fetchSpeciesList().read()
  );

  const [latitude, setLatitude] = useState(coordinates?.lat);
  const [longitude, setLongitude] = useState(coordinates?.lng);
  const [species, setSpecies] = useState<SpeciesData>();
  const [variety, setVariety] = useState<LightVarietyData>();
  const [description, setDescription] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const mandatoryFieldsAreNotFilled = !species || !latitude || !longitude;

  useEffect(() => {
    if (!latitude) setLatitude(currentPosition?.lat);
    if (!longitude) setLongitude(currentPosition?.lng);
  }, [currentPosition, latitude, longitude]);

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);
    setError(false);
    if (species && latitude && longitude) {
      try {
        const spot = await addSpot({
          species,
          variety,
          latitude,
          longitude,
          description,
          user,
        });
        onSubmit(spot);
      } catch (e) {
        setError(true);
      }
    }
    setSubmitting(false);
  };

  const handleSpeciesChange = (event: SelectChangeEvent<number>) => {
    const newSpecies = existingSpecies.find((s) => s.id === event.target.value);
    setSpecies(newSpecies);
  };

  const handleVarietyChange = (event: SelectChangeEvent<number>) => {
    const newVariety = species?.varieties.find(
      (v) => v.id === event.target.value
    );
    setVariety(newVariety);
  };

  return (
    <Form onSubmit={submit}>
      {error ? (
        <FormMsg>
          <ErrorMsg>{t('spot.addFailed')}</ErrorMsg>
        </FormMsg>
      ) : (
        <></>
      )}
      <FormControl fullWidth>
        <InputLabel id="app-add-spot-species-label">
          {t('entity.species.title.singular')}
        </InputLabel>
        <Select
          required
          labelId="app-add-spot-species-label"
          id="app-add-spot-species"
          value={species?.id ?? 0}
          label={t('entity.species.title.singular')}
          onChange={handleSpeciesChange}
        >
          {existingSpecies.map((s) => (
            <MenuItem key={s.id} value={s.id}>
              {s.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="app-add-spot-variety-label">
          {t('entity.variety.title.singular')}
        </InputLabel>
        <Select
          labelId="app-add-spot-variety-label"
          id="app-add-spot-variety"
          value={variety?.id ?? 0}
          label={t('entity.variety.title.singular')}
          onChange={handleVarietyChange}
        >
          <MenuItem key={0} value={0}>
            &nbsp;
          </MenuItem>
          {species?.varieties.map((v) => (
            <MenuItem key={v.id} value={v.id}>
              {v.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        required
        fullWidth
        disabled={readonlyCoordinates || !latitude}
        id="app-add-spot-latitude"
        label={t('entity.spot.field.latitude')}
        value={latitude || ''}
        onChange={(event) => setLatitude(Number(event.target.value))}
        placeholder={t('spot.loading')}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        required
        fullWidth
        disabled={readonlyCoordinates || !longitude}
        id="app-add-spot-longitude"
        label={t('entity.spot.field.longitude')}
        value={longitude || ''}
        onChange={(event) => setLongitude(Number(event.target.value))}
        placeholder={t('spot.loading')}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        fullWidth
        multiline
        rows={4}
        id="app-add-spot-description"
        label={t('entity.spot.field.description')}
        value={description}
        onChange={(event) => setDescription(event.target.value)}
      />
      <SubmitButton
        label={t('form.add')}
        disabled={mandatoryFieldsAreNotFilled}
        submitting={submitting}
      />
    </Form>
  );
}
