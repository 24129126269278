import { ReactNode, useEffect, useState } from 'react';

import { spotsContext, ISpotsContext, buildSpotsContext } from './spotsContext';
import { getSpotsList, SpotData } from 'data/spot';

export function ProvideSpotsContext({ children }: { children: ReactNode }) {
  const [spots, setSpots] = useState<SpotData[]>([]);
  const [spotsContextState, setSpotsContextState] = useState<ISpotsContext>(
    buildSpotsContext(spots, setSpots)
  );

  useEffect(() => {
    const fetchSpots = async () => {
      const newSpots = await getSpotsList();
      setSpots(newSpots);
    };

    fetchSpots();
  }, []);

  useEffect(() => {
    setSpotsContextState(buildSpotsContext(spots, setSpots));
  }, [spots]);

  return (
    <spotsContext.Provider value={spotsContextState}>
      {children}
    </spotsContext.Provider>
  );
}
