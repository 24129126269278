import { createContext, useContext } from 'react';

export interface IPosition {
  lat: number;
  lng: number;
}

export const currentPositionContext = createContext<IPosition | undefined>(
  undefined
);
export const useCurrentPositionContext = () =>
  useContext(currentPositionContext);
