import { useEffect, useState, useMemo } from 'react';
import { Map, GeoJSONSource, LngLat, Point, Popup } from 'mapbox-gl';

import { SpotData } from 'data/spot';
import { formatSpotToMarker } from 'data/format';
import { IMapClickHandler, IMarker, MarkerType } from 'components/ui';

export function useMapContent(spots: SpotData[]) {
  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [clickCoordinates, setClickCoordinates] = useState<LngLat>();

  const popupMgr = useMemo(() => {
    const container = document.createElement('div');
    return clickOnMapPopupManager(container);
  }, []);
  const [clickHandler] = useState<IMapClickHandler>({
    clickOnMap: (map: Map, center: LngLat) => {
      setClickCoordinates(center);
      popupMgr.createPopup(map, center);
    },
    clickOnMarker,
    clickOnCluster,
  });

  useEffect(() => {
    const newMarkers = spots.map((s) => formatSpotToMarker(s));
    setMarkers(newMarkers);
  }, [spots]);

  return { markers, clickHandler, popupMgr, clickCoordinates };
}

function clickOnMapPopupManager(container: HTMLDivElement) {
  let popup: Popup | undefined;

  return {
    container,
    createPopup: (map: Map, center: LngLat) => {
      popup = new Popup().setLngLat(center).setDOMContent(container).addTo(map);
      return popup;
    },
    removePopup: () => popup?.remove(),
  };
}

function clickOnMarker(map: Map, center: LngLat, marker: IMarker) {
  new Popup({ closeOnMove: true })
    .setLngLat(center)
    .setOffset([0, -38])
    .setHTML(getMarkerPopup(marker))
    .addTo(map);
}

function getMarkerPopup(marker: IMarker) {
  if (marker.type !== MarkerType.SPOT) return '';

  const variety = marker.variety ?? '';
  const title = `<div class="app-mapbox-popup-title">${marker.species} ${variety}</div>`;
  const description = marker.description
    ? `<div class="app-mapbox-popup-content">${marker.description}</div>`
    : '';
  const user = `<div class="app-mapbox-popup-user">trouvé par&nbsp;<span>${marker.user}<span></div>`;

  return [title, description, user].join('');
}

function clickOnCluster(map: Map, point: Point) {
  const features = map.queryRenderedFeatures(point, {
    layers: ['clusters'],
  });
  const clusterId = features[0].properties?.cluster_id;
  const source = map.getSource('markers') as GeoJSONSource;

  source.getClusterExpansionZoom(clusterId, (err, zoom) => {
    if (err) return;

    const geometry = features[0].geometry as { coordinates: number[] };

    map.easeTo({
      center: geometry.coordinates as [number, number],
      zoom: zoom + 1,
    });
  });
}
