import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { DefaultTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { AddSpotDialog } from 'components/domain';
import { Mapbox, ImageButton, Title3 } from 'components/ui';
import { useSpotsContext } from 'contexts';
import { SpotData } from 'data/spot';
import { useNearbySpots, useMapContent } from './hooks';
import fruitBanner from 'images/files/fruit-banner.jpg';

import {
  MapContainer,
  ButtonContainer,
  OpenMap,
  CloseMap,
} from './NearbySpots.styled';

interface INearbySpotsProps {
  displayMapBP: (theme: DefaultTheme) => string;
}

export function NearbySpots({ displayMapBP }: INearbySpotsProps) {
  const { t } = useTranslation();
  const { spots, addSpot } = useSpotsContext();
  const { loadMap, fullScreenMgr } = useNearbySpots({ displayMapBP });
  const { markers, clickHandler, popupMgr, clickCoordinates } =
    useMapContent(spots);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const overflow = fullScreenMgr.isFullScreen ? 'hidden' : 'visible';
    document.body.style.overflow = overflow;
  }, [fullScreenMgr.isFullScreen]);

  const onClose = () => {
    setOpen(false);
    popupMgr.removePopup();
  };

  const onSubmit = (spot: SpotData) => {
    addSpot(spot);
    onClose();
  };

  const AddSpotButton = () =>
    createPortal(
      <Button variant="contained" color="success" onClick={() => setOpen(true)}>
        {t('spot.add')}
      </Button>,
      popupMgr.container
    );

  return (
    <>
      <ButtonContainer
        onClick={fullScreenMgr.toggle}
        displayMapBP={displayMapBP}
      >
        <ImageButton url={fruitBanner} name={t('home.displayNearbySpots')} />
      </ButtonContainer>
      <MapContainer
        elevation={5}
        $fullScreen={fullScreenMgr.isFullScreen}
        $displayMapBP={displayMapBP}
      >
        {fullScreenMgr.isFullScreen ? (
          <CloseMap onClick={fullScreenMgr.toggle}>X</CloseMap>
        ) : (
          <OpenMap onClick={fullScreenMgr.toggle}>
            <Title3>{t('home.clickToExtend')}</Title3>
          </OpenMap>
        )}
        <Mapbox load={loadMap} markers={markers} clickHandler={clickHandler} />
      </MapContainer>
      <AddSpotButton />
      <AddSpotDialog
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        coordinates={clickCoordinates}
        readonlyCoordinates
      />
    </>
  );
}
