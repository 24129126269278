import { ReactNode, useEffect, useState } from 'react';

import { currentPositionContext, IPosition } from './currentPositionContext';

export function ProvideCurrentPositionContext({
  children,
}: {
  children: ReactNode;
}) {
  const [currentPosition, setCurrentPosition] = useState<IPosition>();

  useEffect(() => {
    const watchManager = buildWatchManager();
    const updatePosition = (position: GeolocationPosition) => {
      const newPosition = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      if (!areSamePositions(watchManager.position.get(), newPosition)) {
        watchManager.position.set(newPosition);
        setCurrentPosition(newPosition);
      }
    };

    watchPosition(watchManager, updatePosition);
    const clearEvents = optimimzeWatching(watchManager, updatePosition);

    return () => {
      stopWatchingPosition(watchManager);
      clearEvents();
    };
  }, []);

  return (
    <currentPositionContext.Provider value={currentPosition}>
      {children}
    </currentPositionContext.Provider>
  );
}

function areSamePositions(pos1?: IPosition, pos2?: IPosition) {
  return pos1?.lat === pos2?.lat && pos1?.lng === pos2?.lng;
}

function buildWatchManager() {
  let watchId: number | undefined = undefined;
  let position: IPosition | undefined = undefined;
  return {
    watchId: {
      get: () => watchId,
      set: (newId: number) => {
        watchId = newId;
      },
      clear: () => {
        watchId = undefined;
      },
    },
    position: {
      get: () => position,
      set: (newPosition: IPosition) => {
        position = newPosition;
      },
    },
  };
}

function watchPosition(
  watchManager: ReturnType<typeof buildWatchManager>,
  updatePosition: (position: GeolocationPosition) => void
) {
  if (!watchManager.watchId.get()) {
    const watchId = navigator.geolocation.watchPosition(
      updatePosition,
      (error) => {
        console.warn(`ERROR(${error.code}): ${error.message}`);
      },
      { enableHighAccuracy: true }
    );
    watchManager.watchId.set(watchId);
  }
}

function stopWatchingPosition(
  watchManager: ReturnType<typeof buildWatchManager>
) {
  const watchId = watchManager.watchId.get();
  if (watchId) {
    navigator.geolocation.clearWatch(watchId);
    watchManager.watchId.clear();
  }
}

function optimimzeWatching(
  watchManager: ReturnType<typeof buildWatchManager>,
  updatePosition: (position: GeolocationPosition) => void
) {
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      watchPosition(watchManager, updatePosition);
    } else {
      stopWatchingPosition(watchManager);
    }
  };
  document.addEventListener('visibilitychange', handleVisibilityChange);

  // Safari fix (https://caniuse.com/?search=visibilitychange)
  const handlePageHide = () => {
    stopWatchingPosition(watchManager);
  };
  window.addEventListener('pagehide', handlePageHide);

  return () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
    window.removeEventListener('pagehide', handlePageHide);
  };
}
