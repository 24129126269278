export { resourceManager } from './resourceManager';

export type NotPersisted<T extends { id: number }> = Omit<T, 'id'>;

export function getIriReference() {
  const pathname = new URL(process.env.REACT_APP_BACK_URL as string).pathname;
  return pathname.slice(-1) === '/' ? pathname.slice(0, -1) : pathname;
}

interface JsonLD<T> {
  '@context': string;
  '@id': string;
  '@type': string;
  'hydra:member': T;
}

export function get<T>(url: string) {
  const inits: RequestInit = {
    method: 'GET',
    headers: headers(),
    mode: 'cors',
    cache: 'default',
  };

  return request<T>(url, inits);
}

export function getHydraMember<T>(url: string) {
  return get<JsonLD<T>>(url).then((response) => response['hydra:member']);
}

export function post<T, U>(url: string, body: T) {
  const inits: RequestInit = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(body),
    mode: 'cors',
    cache: 'default',
  };

  return request<U>(url, inits);
}

function headers() {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/ld+json');

  return headers;
}

function request<T>(url: string, inits: RequestInit) {
  const fetchInits = includeSafeCredentials(url, inits);

  return new Promise((resolve: (response: T) => void, reject) => {
    fetch(url, fetchInits)
      .then((response) => {
        if (response.ok) {
          const contentType = response.headers.get('Content-Type');
          const isJson = contentType?.match(/application\/(ld\+)?json/g);

          const formattedResponse = isJson ? response.json() : response.blob();
          formattedResponse
            .catch((error) => {
              console.error(error);
              reject(response);
            })
            .then((body: T) => resolve(body));
        } else {
          reject(response);
        }
      })
      .catch((error) => reject(error));
  });
}

function includeSafeCredentials(url: string, inits: RequestInit): RequestInit {
  const backUrl = process.env.REACT_APP_BACK_URL;
  if (backUrl && url.startsWith(backUrl)) {
    return {
      ...inits,
      credentials: 'include',
    };
  }

  return inits;
}
