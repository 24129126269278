import styled from 'styled-components';
import { Button } from '@mui/material';

import { Loading } from 'components/ui';

const IconContainer = styled.div`
  height: calc(0.875rem * 1.75);
`;

interface SubmitButtonProps {
  label: string;
  submitting: boolean;
  disabled?: boolean;
}

export function SubmitButton({
  label,
  submitting,
  disabled = false,
}: SubmitButtonProps) {
  return (
    <Button
      type="submit"
      disabled={disabled || submitting}
      variant="contained"
      color="success"
      fullWidth
    >
      {submitting ? (
        <IconContainer>
          <Loading />
        </IconContainer>
      ) : (
        label
      )}
    </Button>
  );
}
