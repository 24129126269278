import { Header, HeaderContent } from 'components/ui';

import { LayoutGrid, Nav, FixedNav, Background } from './Layout.styled';

interface LayoutProps {
  headerContent?: HeaderContent;
  background?: string;
  children?: React.ReactNode;
}

export function Layout({ headerContent, children }: LayoutProps) {
  return (
    <LayoutGrid>
      <Nav />
      <FixedNav>
        {headerContent ? <Header content={headerContent} /> : <></>}
      </FixedNav>
      <Background>{children}</Background>
    </LayoutGrid>
  );
}
