import { BaseImage, ImageProps } from './BaseImage';

import src from './files/fallback.webp';

export function FallbackImg({ alt = 'Error', fullWidth }: ImageProps) {
  return (
    <BaseImage
      src={src}
      alt={alt}
      width={705}
      height={845}
      fullWidth={fullWidth}
    />
  );
}
