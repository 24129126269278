import { BaseImage, ImageProps } from './BaseImage';

import src from './files/mini-logo.png';

export function MiniLogo({ alt = 'Home', fullWidth }: ImageProps) {
  return (
    <BaseImage
      src={src}
      alt={alt}
      width={696}
      height={844}
      fullWidth={fullWidth}
    />
  );
}
