import styled, { DefaultTheme } from 'styled-components';
import { Paper } from '@mui/material';

import { headerHeight } from 'components/ui';

interface IMapContainerProps {
  $fullScreen?: boolean;
  $displayMapBP: (theme: DefaultTheme) => string;
}

export const MapContainer = styled(Paper)<IMapContainerProps>`
  position: relative;
  display: none;
  height: 100%;

  ${({ $displayMapBP, theme }) => $displayMapBP(theme)} {
    display: block;
  }

  ${({ $fullScreen }) =>
    $fullScreen &&
    `
      display: block;
      position: fixed;
      top: ${headerHeight};
      left: 0;
      height: calc(100vh - ${headerHeight});
      width: 100vw;
      z-index: 1000;
    `}
`;

export const OpenMap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  z-index: 1000;
  color: rgba(0, 0, 0, 0);

  &:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    color: ${({ theme }) => theme.palette.common.white};
  }
`;
export const CloseMap = styled.div`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  height: 2em;
  width: 2em;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 2em;
`;

interface IButtonContainerProps {
  displayMapBP: (theme: DefaultTheme) => string;
}

export const ButtonContainer = styled.div<IButtonContainerProps>`
  display: block;
  ${({ displayMapBP, theme }) => displayMapBP(theme)} {
    display: none;
  }
`;
