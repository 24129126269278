import { ReactNode, useEffect, useState } from 'react';

import { userContext, IUserContext, buildUserContext } from './userContext';
import { fetchCurrentUser, UserData } from 'data/user';

export function ProvideUserContext({ children }: { children: ReactNode }) {
  const initialUser = fetchCurrentUser().read();

  const [user, setUser] = useState<UserData>(initialUser);
  const [userContextState, setUserContextState] = useState<IUserContext>(
    buildUserContext(user, setUser)
  );

  useEffect(() => {
    setUserContextState(buildUserContext(user, setUser));
  }, [user]);

  return (
    <userContext.Provider value={userContextState}>
      {children}
    </userContext.Provider>
  );
}
