type suspenseFetchReturn<T> = { read: () => T };

interface CachedResource<T> {
  expiryDate: number | undefined;
  resource: suspenseFetchReturn<T>;
}

interface ResourcesCache<T> {
  [key: string]: CachedResource<T>;
}

// Cache that contains the data ressources and their expected caching lifetime
const cache: ResourcesCache<any> = {};

export function resourceManager<T>(
  key: string,
  promise: () => Promise<T>,
  lifetime: number = 5
) {
  if (cache[key] === undefined || resourceIsExpired<T>(cache[key])) {
    const updateExpiryDate = () => {
      cache[key].expiryDate = Date.now() + lifetime * 1000;
    };
    cache[key] = {
      resource: suspenseFetch(promise(), updateExpiryDate),
      expiryDate: undefined,
    };
  }

  return cache[key].resource as suspenseFetchReturn<T>;
}

function resourceIsExpired<T>(cachedResource: CachedResource<T>) {
  return (
    cachedResource.expiryDate !== undefined &&
    cachedResource.expiryDate < Date.now()
  );
}

function suspenseFetch<T>(
  promise: Promise<T>,
  onSuccess = () => {}
): suspenseFetchReturn<T> {
  let status = 'pending';
  let response: T;
  let error: Error;

  const suspender = promise.then(
    (res: T) => {
      status = 'success';
      response = res;
      onSuccess();
    },
    (err: Error) => {
      status = 'error';
      error = err;
    }
  );
  const read = () => {
    switch (status) {
      case 'pending':
        throw suspender;
      case 'error':
        throw error;
      default:
        return response;
    }
  };

  return { read };
}
