import {
  getHydraMember,
  post,
  resourceManager,
  NotPersisted,
  getIriReference,
} from 'tools/fetch';
import { LightSpeciesData } from 'data/species';
import { LightVarietyData } from 'data/variety';
import { LightUserData } from 'data/user';

export interface SpotData {
  id: number;
  species: LightSpeciesData;
  variety?: LightVarietyData;
  latitude: number;
  longitude: number;
  description?: string;
  user: LightUserData;
}

interface IAddSpotBody {
  species: string;
  variety?: string;
  latitude: number;
  longitude: number;
  description?: string;
  user: string;
}

export function getSpotsList() {
  const url = `${process.env.REACT_APP_BACK_URL}/api/spots`;
  return getHydraMember<SpotData[]>(url);
}

export function fetchSpotsList() {
  return resourceManager('fetchSpotsList', () => getSpotsList());
}

export function fetchSpot(id: number) {
  const url = `${process.env.REACT_APP_BACK_URL}/api/spots/${id}`;
  const request = () => getHydraMember<SpotData>(url);

  return resourceManager(`fetchSpot.${id}`, request);
}

export function fetchSpeciesSpots(speciesId: number) {
  const url = `${process.env.REACT_APP_BACK_URL}/api/species/${speciesId}/spots`;
  const request = () => getHydraMember<SpotData[]>(url);

  return resourceManager(`fetchSpeciesSpots.${speciesId}`, request);
}

export function fetchVarietySpots(varietyId: number) {
  const url = `${process.env.REACT_APP_BACK_URL}/api/varieties/${varietyId}/spots`;
  const request = () => getHydraMember<SpotData[]>(url);

  return resourceManager(`fetchVarietySpots.${varietyId}`, request);
}
export function addSpot(spot: NotPersisted<SpotData>) {
  const body: IAddSpotBody = {
    species: `${getIriReference()}/api/species/${spot.species.id}`,
    latitude: spot.latitude,
    longitude: spot.longitude,
    user: `${getIriReference()}/api/users/${spot.user.id}`,
  };
  if (spot.variety) {
    body.variety = `${getIriReference()}/api/varieties/${spot.variety.id}`;
  }
  if (spot.description) {
    body.description = spot.description;
  }

  const url = `${process.env.REACT_APP_BACK_URL}/api/spots`;
  return post<IAddSpotBody, SpotData>(url, body);
}
