import {
  ButtonContainer,
  ImageSrc,
  ImageBackdrop,
  Image,
  ImageText,
  ImageMarked,
} from './ImageButton.styled';

interface ImageButtonProps {
  url: string;
  name: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export function ImageButton({ url, name, onClick }: ImageButtonProps) {
  return (
    <ButtonContainer focusRipple onClick={onClick}>
      <ImageSrc style={{ backgroundImage: `url(${url})` }} />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
        <ImageText variant="h5">
          {name}
          <ImageMarked className="MuiImageMarked-root" />
        </ImageText>
      </Image>
    </ButtonContainer>
  );
}
