import { useTranslation } from 'react-i18next';

import { useHeaderContent } from 'components/domain';
import { Layout, MainContent, Title1, Title3 } from 'components/ui';

import {
  HomeTitle,
  FirstScreen,
  TitleText,
  Description,
} from '../Home/Home.styled';

export function PageNotFound() {
  const { t } = useTranslation();
  const { headerContent } = useHeaderContent();

  return (
    <Layout headerContent={headerContent}>
      <MainContent>
        <FirstScreen>
          <HomeTitle>
            <TitleText>
              <Title1>{t('brand')}</Title1>
              <Title3>{t('slogan')}</Title3>
            </TitleText>
          </HomeTitle>
          <Description>
            <span>{t('pageNotFound.title')}</span>
            <br />
            <span>{t('pageNotFound.description')}</span>
          </Description>
          <div></div>
        </FirstScreen>
      </MainContent>
    </Layout>
  );
}
