import { getHydraMember, resourceManager } from 'tools/fetch';
import { LightVarietyData } from 'data/variety';

export interface LightSpeciesData {
  id: number;
  name: string;
  markerColor: string;
}

export interface SpeciesData extends LightSpeciesData {
  varieties: LightVarietyData[];
}

export function fetchSpeciesList() {
  const url = `${process.env.REACT_APP_BACK_URL}/api/species`;
  const request = () => getHydraMember<SpeciesData[]>(url);

  return resourceManager('fetchSpeciesList', request);
}

export function fetchSpecies(id: number) {
  const url = `${process.env.REACT_APP_BACK_URL}/api/species/${id}`;
  const request = () => getHydraMember<SpeciesData>(url);

  return resourceManager(`fetchSpecies.${id}`, request);
}
