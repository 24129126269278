import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { useUserContext } from 'contexts';
import {
  AddSpotForm,
  IAddSpotFormProps,
  LoginForm,
  RegisterButton,
} from 'components/domain';
import {
  Loading,
  FormContainer,
  CancelButton,
  InfoMsg,
  FormMsg,
} from 'components/ui';

interface IAddSpotProps extends IAddSpotFormProps {
  open: boolean;
  onClose?: () => void;
}

export function AddSpotDialog({
  open,
  onClose,
  onSubmit,
  coordinates,
  readonlyCoordinates = false,
}: IAddSpotProps) {
  const { user } = useUserContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullScreen={fullScreen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('spot.add')}</DialogTitle>
      <DialogContent id="alert-dialog-description">
        <FormContainer>
          <Suspense fallback={<Loading height="5em" />}>
            {user.isAuthenticated ? (
              <AddSpotForm
                onSubmit={onSubmit}
                coordinates={coordinates}
                readonlyCoordinates={readonlyCoordinates}
              />
            ) : (
              <>
                <FormMsg>
                  <InfoMsg>{t('spot.addCondition')}</InfoMsg>
                </FormMsg>
                <LoginForm />
              </>
            )}
            <CancelButton
              variant="outlined"
              color="success"
              fullWidth
              onClick={onClose}
            >
              {t('form.cancel')}
            </CancelButton>
            {user.isAuthenticated ? <></> : <RegisterButton />}
          </Suspense>
        </FormContainer>
      </DialogContent>
    </Dialog>
  );
}
