import styled from 'styled-components';
import { ErrorOutline, InfoOutlined } from '@mui/icons-material';

const Base = styled.div`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.spacing(4)} 1fr;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: 4px;
  text-align: justify;
`;

const Error = styled(Base)`
  border: 1px solid ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.error.main};
`;

const Info = styled(Base)`
  border: 1px solid ${({ theme }) => theme.palette.success.main};
  color: ${({ theme }) => theme.palette.success.main};
`;

interface IMessageProps {
  children: React.ReactNode;
}

export function ErrorMsg({ children }: IMessageProps) {
  return (
    <Error>
      <ErrorOutline />
      <div>{children}</div>
    </Error>
  );
}

export function InfoMsg({ children }: IMessageProps) {
  return (
    <Info>
      <InfoOutlined />
      <div>{children}</div>
    </Info>
  );
}
