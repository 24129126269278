import styled from 'styled-components';

export const UserName = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

export const LogoContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.spacing(6)} 1fr;
  grid-template-rows: ${({ theme }) => theme.spacing(5)};
`;
