import { BaseImage, ImageProps } from './BaseImage';

import src from './files/loadingHicker.gif';

export function LoadingImg({ alt = 'Loading', fullWidth }: ImageProps) {
  return (
    <BaseImage
      src={src}
      alt={alt}
      width={571}
      height={571}
      fullWidth={fullWidth}
    />
  );
}
