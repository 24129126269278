import { useEffect, useState } from 'react';
import { DefaultTheme } from 'styled-components';
import { useTheme, useMediaQuery } from '@mui/material';
import { throttle } from 'lodash';

interface IuseNearbySpotsProps {
  displayMapBP: (theme: DefaultTheme) => string;
}

export function useNearbySpots({ displayMapBP }: IuseNearbySpotsProps) {
  const theme = useTheme();
  const shouldLoadMap = useMediaQuery(displayMapBP(theme));
  const [loadMap, setLoadMap] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  useEffect(() => {
    const activateLoading = () => setLoadMap(isFullScreen || shouldLoadMap);
    const event = throttle(activateLoading, 100);

    activateLoading();
    window.addEventListener('resize', event);

    return () => window.removeEventListener('resize', event);
  }, [isFullScreen, shouldLoadMap]);

  useEffect(() => {
    // Sometimes the map does not detect the resize when done programmatically
    window.dispatchEvent(new Event('resize'));
  }, [loadMap, isFullScreen]);

  const toggleFullScreen = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (!isFullScreen) {
      setLoadMap(true);
    }
    setIsFullScreen(!isFullScreen);
  };

  return { loadMap, fullScreenMgr: { isFullScreen, toggle: toggleFullScreen } };
}
