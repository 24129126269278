import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const RegisterLink = styled.a`
  display: block;
  padding-top: ${({ theme }) => theme.spacing(1)};
  text-align: right;
  color: ${({ theme }) => theme.palette.common.black};
`;

export function RegisterButton() {
  const { t } = useTranslation();

  return (
    <RegisterLink href={`${process.env.REACT_APP_BACK_URL}/register`}>
      {t('login.register')}
    </RegisterLink>
  );
}
