import { createTheme } from '@mui/material';

const defaultFontSizePx = 16;

export default createTheme({
  breakpoints: {
    unit: 'em',
    values: {
      xs: 0,
      sm: 592 / defaultFontSizePx,
      md: 960 / defaultFontSizePx,
      lg: 1280 / defaultFontSizePx,
      xl: 1920 / defaultFontSizePx,
    },
  },
  spacing: (factor: number) => `${0.5 * factor}em`,
  typography: {
    fontFamily:
      '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
  },
  palette: {
    primary: {
      light: '#48569f',
      main: '#0e2e70',
      dark: '#000544',
      contrastText: '#fff',
    },
    secondary: {
      light: '#646464',
      main: '#3a3a3a',
      dark: '#141414',
      contrastText: '#fff',
    },
  },
});
