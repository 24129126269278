import styled from 'styled-components';

import { LoadingImg } from 'images';

interface LoadingProps {
  height?: string;
}

const Container = styled.div<LoadingProps>`
  display: flex;
  justify-content: center;
  height: ${({ height }) => height ?? '100%'};
`;

export function Loading({ height }: LoadingProps) {
  return (
    <Container height={height}>
      <LoadingImg />
    </Container>
  );
}
