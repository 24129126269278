import { useState } from 'react';
import { MenuItem } from '@mui/material';

import { HeaderButton } from './HeaderButton';
import { HeaderNodeLink, HeaderNodeAction, buildHeaderNode } from './Header';

import { HeaderMuiMenu } from './Header.styled';

interface HeaderMenuProps {
  menuKey: string;
  menu: Array<HeaderNodeLink | HeaderNodeAction>;
  children: React.ReactNode;
}

export function HeaderMenu({ menuKey, menu, children }: HeaderMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <HeaderButton
        id={`${menuKey}-button`}
        aria-controls={open ? `${menuKey}-menu` : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {children}
      </HeaderButton>
      <HeaderMuiMenu
        id={`${menuKey}-menu`}
        aria-labelledby={`${menuKey}-button`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        keepMounted
        disableScrollLock
      >
        {menu
          .filter((child) => child.display ?? true)
          .map((child) => (
            <MenuItem key={child.key} onClick={handleClose}>
              {buildHeaderNode(child)}
            </MenuItem>
          ))}
      </HeaderMuiMenu>
    </>
  );
}
