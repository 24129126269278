import { get, resourceManager } from 'tools/fetch';

interface LoggedOffUserData {
  isAuthenticated: false;
  isAdmin: false;
  username: '';
  id: 0;
}

interface LoggedInUserData {
  isAuthenticated: true;
  isAdmin: boolean;
  username: string;
  id: number;
}

export type UserData = LoggedOffUserData | LoggedInUserData;
export interface LightUserData {
  username: string;
  id: number;
}

export interface BackEndUserData {
  user: Omit<LoggedInUserData, 'isAuthenticated'> | null;
}

export const emptyUser: UserData = {
  isAuthenticated: false,
  isAdmin: false,
  username: '',
  id: 0,
};

export function formatBackEndUser(backEndUser: BackEndUserData): UserData {
  if (backEndUser.user) {
    return { isAuthenticated: true, ...backEndUser.user };
  } else {
    return emptyUser;
  }
}

export function fetchCurrentUser() {
  const url = `${process.env.REACT_APP_BACK_URL}/currentUser`;
  const request = () =>
    get<BackEndUserData>(url).then((u) => formatBackEndUser(u));

  return resourceManager('fetchCurrentUser', request);
}
