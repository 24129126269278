import { Map, GeoJSONSource, GeoJSONSourceRaw, SymbolLayout } from 'mapbox-gl';

import { IImage, getImages } from './mapboxImages';

export enum MarkerType {
  SPOT,
}

export interface IPositionMarker {
  lng: number;
  lat: number;
}

interface IPinMarker extends IPositionMarker {
  color: string;
  label: string;
}

interface ISpotMarker extends IPinMarker {
  type: MarkerType.SPOT;
  species: string;
  species_id: number;
  variety?: string;
  description?: string;
  user: string;
}

export type IMarker = ISpotMarker;

export async function loadMarkers(
  map: Map,
  markers: IMarker[],
  position?: IPositionMarker
) {
  await loadAllImages(map, getImages());

  map.addSource('markers', buildSource(buildFeatures(markers)));
  const positionFeature = position ? [buildMarkerFeature(position)] : [];
  map.addSource('position', buildSource(positionFeature));
  displayMarkers(map);
}

export function updateMarkers(map: Map, markers: IMarker[]) {
  const source = map.getSource('markers') as GeoJSONSource;

  source.setData({
    type: 'FeatureCollection',
    features: buildFeatures(markers),
  });
}

export function updateCurrentPositionMarker(
  map: Map,
  position: IPositionMarker
) {
  const source = map.getSource('position') as GeoJSONSource;

  source.setData({
    type: 'FeatureCollection',
    features: [buildMarkerFeature(position)],
  });
}

function buildFeatures(markers: IMarker[]) {
  return markers.map((m) => buildMarkerFeature(m));
}

function loadAllImages(map: Map, images: IImage[]) {
  return Promise.all(images.map((i) => loadImage(map, i.code, i.url)));
}

function loadImage(map: Map, name: string, imageUrl: string) {
  return new Promise<void>(function (resolve, reject) {
    map.loadImage(imageUrl, function (error, image) {
      if (error || !image) {
        reject(error);
      } else {
        map.addImage(name, image);
        resolve();
      }
    });
  });
}

function displayMarkers(map: Map) {
  const defaultLayout = {
    'icon-allow-overlap': true,
    'text-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-ignore-placement': true,
    'icon-anchor': 'bottom',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 15,
    'text-offset': [0, -1.7],
  } as SymbolLayout;

  map.addLayer({
    id: 'pin-shadows',
    type: 'symbol',
    source: 'markers',
    layout: {
      ...defaultLayout,
      'icon-image': 'shadow',
      'icon-offset': [10, 0],
    },
  });
  map.addLayer({
    id: 'pins',
    type: 'symbol',
    source: 'markers',
    filter: ['!', ['has', 'cluster']],
    layout: {
      ...defaultLayout,
      'icon-image': '{color}',
      'text-field': '{label}',
    },
  });
  map.addLayer({
    id: 'clusters',
    type: 'symbol',
    source: 'markers',
    filter: ['has', 'cluster'],
    layout: {
      ...defaultLayout,
      'icon-image': [
        'case',
        ['==', ['get', 'min_species_id'], ['get', 'max_species_id']],
        [
          'slice',
          ['get', 'all_colors'],
          0,
          ['/', ['length', ['get', 'all_colors']], ['get', 'point_count']],
        ],
        'grey',
      ],
      'text-field': [
        'case',
        ['==', ['get', 'min_species_id'], ['get', 'max_species_id']],
        [
          'slice',
          ['get', 'all_labels'],
          0,
          ['/', ['length', ['get', 'all_labels']], ['get', 'point_count']],
        ],
        ['get', 'point_count_abbreviated'],
      ],
    },
    paint: {
      'text-color': ['case', ['get', 'same_species'], 'black', 'white'],
    },
  });

  map.addLayer({
    id: 'position',
    type: 'circle',
    source: 'position',
    paint: {
      'circle-color': '#C41E3A',
      'circle-radius': 6,
      'circle-stroke-width': 2,
      'circle-stroke-color': '#000',
    },
  });
}

function buildMarkerFeature(marker: IPositionMarker) {
  return {
    type: 'Feature' as 'Feature',
    geometry: {
      type: 'Point' as 'Point',
      coordinates: [marker.lng, marker.lat],
    },
    properties: { ...marker },
  };
}

function buildSource(features: ReturnType<typeof buildMarkerFeature>[]) {
  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features,
    },
    cluster: true,
    clusterMaxZoom: 1000,
    clusterRadius: 15,
    clusterProperties: {
      min_species_id: ['min', ['get', 'species_id']],
      max_species_id: ['max', ['get', 'species_id']],
      all_labels: ['concat', ['get', 'label']],
      all_colors: ['concat', ['get', 'color']],
    },
  } as GeoJSONSourceRaw;
}
