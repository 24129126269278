import { Typography } from '@mui/material';

import { TitleContainer, ParagraphContainer } from './Text.styled';

interface ChildrenProp {
  className?: string;
  children: React.ReactNode;
}

export function MainTitle({ className, children }: ChildrenProp) {
  return (
    <TitleContainer className={className}>
      <Typography variant="h1" align="center">
        {children}
      </Typography>
    </TitleContainer>
  );
}

export function Title1({ className, children }: ChildrenProp) {
  return (
    <TitleContainer className={className}>
      <Typography variant="h2">{children}</Typography>
    </TitleContainer>
  );
}

export function Title2({ className, children }: ChildrenProp) {
  return (
    <TitleContainer className={className}>
      <Typography variant="h3">{children}</Typography>
    </TitleContainer>
  );
}

export function Title3({ className, children }: ChildrenProp) {
  return (
    <TitleContainer className={className}>
      <Typography variant="h4">{children}</Typography>
    </TitleContainer>
  );
}

export function Title4({ className, children }: ChildrenProp) {
  return (
    <TitleContainer className={className}>
      <Typography variant="h5">{children}</Typography>
    </TitleContainer>
  );
}

export function Paragraph({ className, children }: ChildrenProp) {
  return (
    <ParagraphContainer className={className}>
      <Typography align="justify">{children}</Typography>
    </ParagraphContainer>
  );
}
