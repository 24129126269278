import styled from 'styled-components';

export const MiniLogo = styled.span`
  position: relative;
  max-width: 5em;
  height: 100%;

  & img {
    position: absolute;
    top: 0;
    right: 0;
    padding: ${({ theme }) => theme.spacing(2)};
    ${({ theme }) => theme.breakpoints.down('lg')} {
      position: relative;
    }
  }
`;

export const UserName = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)};
`;
