import { Suspense } from 'react';
import './i18n';

import { Layout } from 'components/ui';

interface I18nProps {
  children: React.ReactNode;
}

export function AppI18n({ children }: I18nProps) {
  return <Suspense fallback={<Layout />}>{children}</Suspense>;
}
