import { useRef, useState, useEffect } from 'react';
import mapboxgl, { Map } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { useInitializeMap } from './useInitializeMap';
import { useCurrentPositionContext } from 'contexts';
import {
  IMarker,
  loadMarkers,
  updateMarkers,
  updateCurrentPositionMarker,
} from './mapboxMarkers';
import { IMapClickHandler, manageInteractivity } from './mapboxInteractivity';

mapboxgl.accessToken = process.env.REACT_APP_MAP_TOKEN as string;

interface IUseMapboxProps {
  markers: IMarker[];
  load: boolean;
  clickHandler: IMapClickHandler;
}

export function useMapbox({ markers, load, clickHandler }: IUseMapboxProps) {
  const { initializeMap } = useInitializeMap();
  const currentPosition = useCurrentPositionContext();

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<Map | null>(null);
  const [mapReady, setMapReady] = useState(false);
  const [mapCentered, setMapCentered] = useState(false);

  useEffect(() => {
    if (!load) return; // load only when needed

    const loadMapContent = async (emptyMap: Map) => {
      loadElevationTerrain(emptyMap);
      await loadMarkers(emptyMap, markers, currentPosition);
    };

    const onLayerChange = async (emptyMap: Map) => {
      setMapReady(false);
      await emptyMap.once('idle');
      await loadMapContent(emptyMap);
      setMapReady(true);
    };

    const init = async () => {
      if (map.current || !mapContainer.current) return; // initialize map only once

      map.current = initializeMap(mapContainer.current, onLayerChange);
      await map.current.once('load');
      await loadMapContent(map.current);
      manageInteractivity({ map: map.current, clickHandler });

      setMapReady(true);
    };

    init();
  }, [load, markers, currentPosition, clickHandler, initializeMap]);

  useEffect(() => {
    if (!map.current || !mapReady) return;
    updateMarkers(map.current, markers);
  }, [markers, mapReady]);

  useEffect(() => {
    if (!map.current || !mapReady || !currentPosition) return;
    updateCurrentPositionMarker(map.current, currentPosition);
  }, [currentPosition, mapReady]);

  useEffect(() => {
    if (!map.current || !currentPosition || !mapReady || mapCentered) return;
    setMapCentered(true);
    map.current.easeTo({
      center: currentPosition,
      zoom: 11,
    });
  }, [currentPosition, mapCentered, mapReady]);

  return { mapContainer };
}

function loadElevationTerrain(map: Map) {
  map.addSource('terrain', {
    type: 'raster-dem',
    url: 'mapbox://mapbox.terrain-rgb',
    tileSize: 512,
    maxzoom: 14,
  });
  map.setTerrain({ source: 'terrain' });
}
