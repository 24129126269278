import { createContext, useContext } from 'react';

import { SpotData } from 'data/spot';

export interface ISpotsContext {
  spots: SpotData[];
  addSpot: (newSpot: SpotData) => void;
}

const emptySpotsContext = {
  spots: [],
  addSpot: (newSpot: SpotData) => {},
};
export const spotsContext = createContext<ISpotsContext>(emptySpotsContext);
export const useSpotsContext = () => useContext(spotsContext);

export function buildSpotsContext(
  spots: SpotData[],
  setSpots: React.Dispatch<React.SetStateAction<SpotData[]>>
): ISpotsContext {
  return {
    spots,
    addSpot: (newSpot: SpotData) => {
      setSpots([...spots, newSpot]);
    },
  };
}
