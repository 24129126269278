import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Checkbox, FormControlLabel } from '@mui/material';

import { ErrorMsg, FormMsg, Form, SubmitButton } from 'components/ui';
import { useUserContext } from 'contexts';
import { logUserIn } from 'data/security';
import { UserData } from 'data/user';

export interface ILoginFormProps {
  onSubmit?: (user: UserData) => void;
}

export function LoginForm({ onSubmit }: ILoginFormProps) {
  const { t } = useTranslation();
  const { setUser } = useUserContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const mandatoryFieldsAreNotFilled = !email || !password;

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);
    setError(false);
    try {
      const newUser = await logUserIn(email, password, rememberMe);
      if (onSubmit) onSubmit(newUser);
      setUser(newUser);
    } catch (e) {
      setError(true);
    }
    setSubmitting(false);
  };

  return (
    <>
      <Form onSubmit={submit}>
        {error ? (
          <FormMsg>
            <ErrorMsg>{t('login.failed')}</ErrorMsg>
          </FormMsg>
        ) : (
          <></>
        )}
        <TextField
          required
          fullWidth
          autoFocus
          id="email"
          label={t('login.email')}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          required
          fullWidth
          type="password"
          id="password"
          label={t('login.password')}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              onChange={(event) => setRememberMe(event.target.checked)}
              name={t('login.rememberme')}
              color="primary"
              inputProps={{ 'aria-label': 'Remember me' }}
            />
          }
          label={t('login.rememberme')}
        />
        <SubmitButton
          label={t('login.submit')}
          disabled={mandatoryFieldsAreNotFilled}
          submitting={submitting}
        />
      </Form>
    </>
  );
}
