import styled, { css } from 'styled-components';
import { Menu } from '@mui/material';

export const headerHeight = '5rem';

export const headerBreakpoints = css`
  ${({ theme }) => theme.breakpoints.between('md', 'xl')} {
    max-width: ${({ theme }) => theme.breakpoints.values.md}em;
  }
  ${({ theme }) => theme.breakpoints.up('xl')} {
    max-width: ${({ theme }) => theme.breakpoints.values.lg}em;
  }
  margin: 0 auto;
`;

export const NavBar = styled.nav`
  ${headerBreakpoints}
  display: flex;
  justify-content: space-between;
`;

export const LeftNavBarContent = styled.div`
  height: ${headerHeight};
  display: flex;
`;

export const RightNavBarContent = styled.div`
  height: ${headerHeight};
  display: flex;
`;

export const HeaderMuiMenu = styled(({ className, ...props }) => (
  <Menu {...props} classes={{ paper: className }} />
))`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 0;
  border-top: 1px solid #000;

  li {
    padding: 0;
  }
`;
