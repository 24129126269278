import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';

import { ProvideSpotsContext, ProvideCurrentPositionContext } from 'contexts';
import { useHeaderContent, NearbySpots, AddSpotHere } from 'components/domain';
import {
  Layout,
  MainContent,
  Title1,
  Title2,
  Title3,
  Title4,
  ImageButton,
} from 'components/ui';
import fruitBanner from 'images/files/fruit-banner-2.jpg';

import {
  largeScreenBP,
  HomeTitle,
  FirstScreen,
  TitleText,
  HomeTitleButton,
  Description,
} from './Home.styled';

export function Home() {
  const { t } = useTranslation();

  const theme = useTheme();
  const screenIsLarge = useMediaQuery(largeScreenBP(theme));
  const { headerContent } = useHeaderContent();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Layout headerContent={headerContent}>
      <ProvideCurrentPositionContext>
        <ProvideSpotsContext>
          <MainContent>
            <FirstScreen>
              <HomeTitle>
                {screenIsLarge ? (
                  <TitleText>
                    <Title1>{t('brand')}</Title1>
                    <Title3>{t('slogan')}</Title3>
                  </TitleText>
                ) : (
                  <>
                    <TitleText>
                      <Title2>{t('brand')}</Title2>
                      <Title4>{t('slogan')}</Title4>
                    </TitleText>
                    <HomeTitleButton onClick={() => setOpen(true)}>
                      {t('spot.add')}
                    </HomeTitleButton>
                  </>
                )}
              </HomeTitle>
              <Description>{t('home.description')}</Description>
              {screenIsLarge ? (
                <ImageButton
                  url={fruitBanner}
                  name={t('home.addSpotHere')}
                  onClick={() => setOpen(true)}
                />
              ) : (
                <></>
              )}
              <NearbySpots displayMapBP={largeScreenBP} />
            </FirstScreen>
            <AddSpotHere
              open={open}
              onClose={() => setOpen(false)}
              onSubmit={() => setOpen(false)}
            />
          </MainContent>
        </ProvideSpotsContext>
      </ProvideCurrentPositionContext>
    </Layout>
  );
}
