import { useSpotsContext } from 'contexts';
import { AddSpotDialog } from 'components/domain';
import { SpotData } from 'data/spot';

interface IAddSpotHereProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export function AddSpotHere({ open, onSubmit, onClose }: IAddSpotHereProps) {
  const { addSpot } = useSpotsContext();

  const onSubmitSpot = (spot: SpotData) => {
    addSpot(spot);
    onSubmit();
  };

  return (
    <AddSpotDialog open={open} onClose={onClose} onSubmit={onSubmitSpot} />
  );
}
