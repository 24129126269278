import { SpotData } from 'data/spot';
import { IMarker, MarkerType } from 'components/ui';

export function formatSpotToMarker(spot: SpotData): IMarker {
  return {
    lng: spot.longitude,
    lat: spot.latitude,
    color: spot.species.markerColor,
    label: spot.species.name.substring(0, 2),
    type: MarkerType.SPOT,
    species: spot.species.name,
    species_id: spot.species.id,
    variety: spot.variety?.name,
    description: spot.description,
    user: spot.user.username,
  };
}
