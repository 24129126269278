import 'mapbox-gl/dist/mapbox-gl.css';
import './mapbox.css';

import { Loading } from 'components/ui';
import { useMapbox, IMarker, IMapClickHandler } from './hooks';

import { MapContainer, MapLoading } from './Mapbox.styled';

interface MapboxProps {
  load?: boolean;
  markers?: IMarker[];
  clickHandler?: IMapClickHandler;
}

export function Mapbox({
  load = true,
  markers = [],
  clickHandler = {},
}: MapboxProps) {
  const { mapContainer } = useMapbox({ markers, load, clickHandler });

  return (
    <MapContainer ref={mapContainer}>
      <MapLoading>
        <Loading />
      </MapLoading>
    </MapContainer>
  );
}
