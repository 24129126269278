import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import CssBaseline from '@mui/material/CssBaseline';

import theme from './theme';

interface ThemeProps {
  children: React.ReactNode;
}

export function AppTheme({ children }: ThemeProps) {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </StyledThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
