import styled from 'styled-components';
import { Paper } from '@mui/material';

import { headerHeight, headerBreakpoints } from 'components/ui';

interface BackgroundProps {
  imgPath?: string;
}

export const LayoutGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${headerHeight} 1fr;
  min-height: 100vh;
`;

export const Nav = styled.div`
  width: 100%;
  height: ${headerHeight};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  z-index: 1000;
`;

export const FixedNav = styled(Nav)`
  position: fixed;
`;

export const Background = styled.section<BackgroundProps>`
  background: #f9f9f1;
  z-index: 100;
`;

export const MainContent = styled(Paper)`
  ${headerBreakpoints}
  padding: ${({ theme }) => theme.spacing(4)};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;
