import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Map, NavigationControl, ScaleControl } from 'mapbox-gl';
// @ts-expect-error
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { StylesControl } from 'mapbox-gl-controls';
import 'mapbox-gl-controls/lib/controls.css';

import { IPositionMarker } from './mapboxMarkers';

interface ICameraPosition {
  center: IPositionMarker;
  zoom: number;
}

interface ILayerLabels {
  outdoors: string;
  satellite: string;
  streets: string;
}

// Paris
const defaultPosition: ICameraPosition = {
  center: { lng: 2.333333, lat: 48.866667 },
  zoom: 4,
};

export function useInitializeMap() {
  const { t } = useTranslation();
  const [layerLabels] = useState<ILayerLabels>({
    outdoors: t('mapbox.outdoors'),
    satellite: t('mapbox.satellite'),
    streets: t('mapbox.streets'),
  });

  const initializeMap = useCallback(
    (container: HTMLDivElement, onLayerChange: (map: Map) => void) => {
      const newMap = new Map({
        container,
        style: 'mapbox://styles/mapbox/outdoors-v11',
        center: defaultPosition.center,
        zoom: defaultPosition.zoom,
        maxZoom: 17,
      });

      manageLanguage(newMap);
      manageLayers(newMap, onLayerChange, layerLabels);
      manageNavigation(newMap);
      displaySky(newMap);
      newMap.addControl(new ScaleControl());

      return newMap;
    },
    [layerLabels]
  );

  return { initializeMap };
}

function manageLanguage(map: Map) {
  const language = document.documentElement.lang;
  map.addControl(new MapboxLanguage({ defaultLanguage: language }));
}

function manageLayers(
  map: Map,
  onLayerChange: (map: Map) => void,
  layerLabels: ILayerLabels
) {
  map.addControl(
    new StylesControl({
      styles: [
        {
          label: layerLabels.outdoors,
          styleName: 'Outdoors',
          styleUrl: 'mapbox://styles/mapbox/outdoors-v11',
        },
        {
          label: layerLabels.satellite,
          styleName: 'Satellite',
          styleUrl: 'mapbox://styles/mapbox/satellite-streets-v11',
        },
        {
          label: layerLabels.streets,
          styleName: 'Mapbox Streets',
          styleUrl: 'mapbox://styles/mapbox/streets-v11',
        },
      ],
      onChange: () => onLayerChange(map),
    }),
    'top-left'
  );
}

function manageNavigation(map: Map) {
  map.addControl(new NavigationControl(), 'top-left');
  map.scrollZoom.setWheelZoomRate(0.02);
}

function displaySky(map: Map) {
  map.on('style.load', () => {
    map.setFog({});
  });
}
