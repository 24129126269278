import styled from 'styled-components';
import { ButtonBase, Typography } from '@mui/material';

export const ButtonContainer = styled(ButtonBase)`
  position: relative;
  height: 10em;
  width: 100%;
  &:hover,
  &.Mui-focusVisible {
    & .MuiImageBackdrop-root {
      opacity: 0.15;
    }
    & .MuiImageMarked-root {
      opacity: 0;
    }
    & .MuiTypography-root {
      border: 0.2em solid currentColor;
    }
  }
`;
export const ImageSrc = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center 40%;
`;

export const ImageBackdrop = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.palette.common.black};
  opacity: 0.4;
  transition: ${({ theme }) => theme.transitions.create('opacity')};
`;

export const Image = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const ImageText = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const ImageMarked = styled.span`
  position: absolute;
  bottom: calc(1em - 2px);
  left: 40%;
  height: 0.2em;
  width: 20%;
  background-color: ${({ theme }) => theme.palette.common.white};
  transition: ${({ theme }) => theme.transitions.create('opacity')};
`;
