import {
  Map,
  EventData,
  LngLat,
  MapMouseEvent,
  MapboxGeoJSONFeature,
  Point,
} from 'mapbox-gl';

import { IMarker } from './mapboxMarkers';

type IClickOnMap = (map: Map, center: LngLat) => void;
type IClickOnMarker = (map: Map, center: LngLat, marker: IMarker) => void;
type IClickOnCluster = (map: Map, point: Point) => void;
export interface IMapClickHandler {
  clickOnMap?: IClickOnMap;
  clickOnMarker?: IClickOnMarker;
  clickOnCluster?: IClickOnCluster;
}

interface IProps {
  map: Map;
  clickHandler?: IMapClickHandler;
}

export function manageInteractivity({ map, clickHandler }: IProps) {
  hoverMarkers(map);
  clickOnMapEvent(map, clickHandler?.clickOnMap);
  clickOnMarkerEvent(map, clickHandler?.clickOnMarker);
  clickOnClusterEvent(map, clickHandler?.clickOnCluster);
}

function hoverMarkers(map: Map) {
  map.on('mouseenter', ['pins', 'clusters'], () => {
    map.getCanvas().style.cursor = 'pointer';
  });
  map.on('mouseleave', ['pins', 'clusters'], () => {
    map.getCanvas().style.cursor = '';
  });
}

function clickOnMapEvent(map: Map, clickOnMap?: IClickOnMap) {
  if (!clickOnMap) return;

  map.on('click', function (e) {
    const markerAtClickPosition = map
      .queryRenderedFeatures(e.point)
      .filter((feature) => feature.source === 'markers');

    if (markerAtClickPosition.length === 0) {
      clickOnMap(map, e.lngLat);
    }
  });
}

function clickOnMarkerEvent(map: Map, clickOnMarker?: IClickOnMarker) {
  if (!clickOnMarker) return;

  map.on('click', 'pins', function (e) {
    if (!e.features) return;
    const coordinates = getEventCoordinates(e);
    const marker = e.features[0].properties as IMarker;

    clickOnMarker(map, coordinates, marker);
  });
}

function clickOnClusterEvent(map: Map, clickOnCluster?: IClickOnCluster) {
  if (!clickOnCluster) return;

  map.on('click', 'clusters', function (e) {
    clickOnCluster(map, e.point);
  });
}

function getEventCoordinates(
  e: MapMouseEvent & {
    features?: MapboxGeoJSONFeature[] | undefined;
  } & EventData
) {
  const features = e.features as MapboxGeoJSONFeature[];
  const geometry = features[0].geometry as { coordinates: number[] };
  const coordinates = geometry.coordinates.slice() as [number, number];

  // Ensure that if the map is zoomed out such that multiple copies of the
  // feature are visible, the popup appears over the copy being pointed to.
  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
  }

  return LngLat.convert(coordinates);
}
