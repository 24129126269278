import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

export const FormContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${({ theme }) =>
      css`calc(${theme.breakpoints.values.sm}em - ${theme.spacing(13)})`};
  }
`;

export const Form = styled.form`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(2)};
`;

export const CancelButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const FormMsg = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
