// Pins
import pin_shadow from 'images/files/mapbox/pins/shadow.png';

// Pin Colors
import pin_yellow from 'images/files/mapbox/pins/yellow.png';
import pin_orange from 'images/files/mapbox/pins/orange.png';
import pin_red from 'images/files/mapbox/pins/red.png';
import pin_pink from 'images/files/mapbox/pins/pink.png';
import pin_purple from 'images/files/mapbox/pins/purple.png';
import pin_blue from 'images/files/mapbox/pins/blue.png';
import pin_green from 'images/files/mapbox/pins/green.png';
import pin_brown from 'images/files/mapbox/pins/brown.png';
import pin_grey from 'images/files/mapbox/pins/grey.png';

export interface IImage {
  code: string;
  url: string;
}

interface IPins {
  shadow: IImage;
  colors: IImage[];
}

const pins: IPins = {
  shadow: { code: 'shadow', url: pin_shadow },
  colors: [
    { code: 'yellow', url: pin_yellow },
    { code: 'orange', url: pin_orange },
    { code: 'red', url: pin_red },
    { code: 'pink', url: pin_pink },
    { code: 'purple', url: pin_purple },
    { code: 'blue', url: pin_blue },
    { code: 'green', url: pin_green },
    { code: 'brown', url: pin_brown },
    { code: 'grey', url: pin_grey },
  ],
};

export function getImages() {
  return [pins.shadow, ...pins.colors];
}
