import styled, { DefaultTheme } from 'styled-components';
import { Typography } from '@mui/material';

import { headerHeight } from 'components/ui';
import background from 'images/files/background.jpg';

export function largeScreenBP(theme: DefaultTheme) {
  return theme.breakpoints.up('sm');
}

export const FirstScreen = styled.div`
  min-height: calc(
    100vh - ${headerHeight} - 2 * ${({ theme }) => theme.spacing(4)}
  );
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(4)};
  grid-template-rows:
    calc(100vh - ${headerHeight} - 10em)
    max-content max-content max-content;

  ${({ theme }) => largeScreenBP(theme)} {
    grid-template-rows: max-content max-content max-content minmax(20em, 1fr);
  }
`;

export const HomeTitle = styled.div`
  position: relative;
  min-height: 10em;
  background: center/cover no-repeat url(${background});
  margin: -${({ theme }) => theme.spacing(4)};
  margin-bottom: 0;

  ${({ theme }) => largeScreenBP(theme)} {
    height: 20em;
  }
`;

export const TitleText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-bottom: ${({ theme }) => theme.spacing(6)};
  text-align: center;
  color: ${({ theme }) => theme.palette.common.white};
  background: rgba(0, 0, 0, 0.2);

  ${({ theme }) => largeScreenBP(theme)} {
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }

  & div {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing(1)};
    & :first-child {
      font-weight: 600;
    }
  }
`;

export const HomeTitleButton = styled.div`
  position: absolute;
  bottom: -${({ theme }) => theme.spacing(2.5)};
  right: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;
  &::before,
  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    width: ${({ theme }) => theme.spacing(2)};
    background-size: 100% 100%;
    background-position: top left, bottom left;
    background-repeat: no-repeat;
  }
  &::before {
    left: -${({ theme }) => theme.spacing(2)};
    background-image: linear-gradient(
      to bottom right,
      transparent 51%,
      ${({ theme }) => theme.palette.secondary.main} 50%
    );
  }
  &::after {
    right: -${({ theme }) => theme.spacing(2)};
    background-image: linear-gradient(
      to bottom right,
      ${({ theme }) => theme.palette.secondary.main} 50%,
      transparent 51%
    );
  }
`;

export const Description = styled(Typography)`
  color: #616161;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: ${({ theme }) => theme.spacing(4)};
  text-align: center;
`;
