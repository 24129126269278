import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ErrorBoundary, Fallback } from 'components/ui';
import { ProvideUserContext } from 'contexts';
import { Router } from 'routes';

import { AppTheme } from './theme';
import { AppI18n } from './i18n';

function LoginFallback() {
  const { t } = useTranslation();
  return <Fallback msg={t('fallback.userLogin')} />;
}

function MainFallback() {
  const { t } = useTranslation();
  return <Fallback msg={t('fallback.error')} />;
}

export function App() {
  return (
    <AppTheme>
      <AppI18n>
        <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE_URL}>
          <ErrorBoundary fallback={<LoginFallback />}>
            <ProvideUserContext>
              <ErrorBoundary fallback={<MainFallback />}>
                <Router />
              </ErrorBoundary>
            </ProvideUserContext>
          </ErrorBoundary>
        </BrowserRouter>
      </AppI18n>
    </AppTheme>
  );
}
