import { AccountCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { useUserContext } from 'contexts';
import { emptyUser } from 'data/user';
import { logUserOut } from 'data/security';
import { Login } from 'components/domain';
import { HeaderContent } from 'components/ui';
import { MiniLogo as MiniLogoImg } from 'images';

import { MiniLogo, UserName } from './HeaderContent.styled';

export function useHeaderContent() {
  const { t } = useTranslation();
  const { user, setUser } = useUserContext();

  const headerContent: HeaderContent = {
    logo: (
      <MiniLogo>
        <MiniLogoImg />
      </MiniLogo>
    ),
    left: [
      { key: 'home', label: t('header.home'), to: '/' },
      {
        key: 'admin',
        display: user.isAdmin,
        label: t('header.admin'),
        to: `${process.env.REACT_APP_BACK_URL}/admin`,
      },
    ],
    right: [
      {
        key: 'login',
        display: !user.isAuthenticated,
        label: <Login />,
      },
      {
        key: 'user',
        display: user.isAuthenticated,
        label: (
          <>
            <AccountCircle />
            <UserName>{user.username}</UserName>
          </>
        ),
        menu: [
          {
            key: 'logout',
            label: t('header.logout'),
            onClick: () => {
              logUserOut().then(() => {
                setUser(emptyUser);
              });
            },
          },
        ],
      },
    ],
  };

  return { headerContent };
}
