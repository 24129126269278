import styled from 'styled-components';

export interface ImageProps {
  alt?: string;
  fullWidth?: boolean;
}

interface BaseImageProps {
  src: string;
  alt: string;
  width: number;
  height: number;
  fullWidth?: boolean;
}

const FullWidthImage = styled.img`
  height: auto;
  width: 100%;
`;

const FullHeightImage = styled.img`
  height: 100%;
  width: auto;
`;

export function BaseImage({
  src,
  alt,
  width,
  height,
  fullWidth = false,
}: BaseImageProps) {
  const Image = fullWidth ? FullWidthImage : FullHeightImage;

  return (
    <Image src={src} alt={alt} width={width} height={height} loading="lazy" />
  );
}
