import { BaseImage, ImageProps } from './BaseImage';

import src from './files/logo.png';

export function Logo({ alt = 'Wild Picking', fullWidth }: ImageProps) {
  return (
    <BaseImage
      src={src}
      alt={alt}
      width={705}
      height={845}
      fullWidth={fullWidth}
    />
  );
}
