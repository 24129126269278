import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Layout, MainContent, Title3 } from 'components/ui';
import { FallbackImg } from 'images';

interface IFallbackProps {
  msg: string;
}

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10em;
`;

const HomeLink = styled.a`
  display: block;
  text-align: right;
  color: ${({ theme }) => theme.palette.common.black};
`;

export function Fallback({ msg }: IFallbackProps) {
  const { t } = useTranslation();

  return (
    <Layout>
      <MainContent>
        <Message>
          <FallbackImg />
          <div>
            <Title3>{msg}...</Title3>
            <HomeLink href="/">{t('fallback.backHome')}</HomeLink>
          </div>
        </Message>
      </MainContent>
    </Layout>
  );
}
